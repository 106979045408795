// Let's import Foundation first, to avoid compile errors
// -------------------------------------------
@import "../node_modules/foundation-sites/scss/foundation";
@include foundation-everything;
// UCR Design 8 Stuff
@import "layout/mixins";
@import "layout/settings";
@import "layout/globals";

@import "layout/masthead-audience";
@import "layout/masthead-site-logo";
@import "layout/primary-navigation";
@import "layout/content-area";
@import "layout/footer-area";
@import "layout/primary-content";

@import "layout/events-silkwrapper";
@import "layout/localist-calendar";
@import "layout/localist-user-pages";
@import "layout/localist-channels";
@import "layout/localist-logintemplate";
