// Styles that are required for localist events - single event page and listing //
// Events scss file for silkwrapper //
// -------------------------------------------
a[href*="//"]:after {
  content: '';
  padding: 0;
}

body.homepage, header, .content-wrapper  {
  font-family: $trade-gothic;
  font-weight: normal;
}

div.content-wrapper {
  background-color: $container-background;
}

div.wrapper {
  margin-top: 34px;
}

div#breadcrumbs {
  position: relative;
  background-color: transparent;
  max-width: 1140px;
  //margin-top: -102px;
  top: -102px;
  border: solid 1px #e6e6e6;
  color: $link-red;
  ul {
    li {
      a {
        color: $link-red;
      }
    }
  }
}

//Top search section
.search_action {
  width: 100%;
  float: none;
  margin-right: 20px;
  margin-bottom: 0.5rem;
  clear: right;
  [type='text'] {
    margin: 0px;
  }
}

@include breakpoint (large) {
  div#breadcrumbs {
    position: relative;
    background-color: transparent;
    margin: -41px 21px 0 0;
    border: solid 1px #e6e6e6;
    top: 0;
  }

  //Top search section
  .search_action {
    width: 30.3333%;
    float: right;
    margin-right: 18px;
    margin-bottom: 0.5rem;
    clear: right;
    [type='text'] {
      margin: 0px;
      border: 1px solid #e1eaf0;
      box-shadow: none;
    }
  }
}

h1, h3.summary, .page_heading, .summary {
  font-family: $trade-gothic;
}

.page_heading {
  h1 {
    font-family: $trade-gothic;
  }
}

.content {
  .content-top {
    .box_image {
      padding-top: 0;
      padding-left: 0;
      img {
        border-radius: 0;
        border: none;
      }
    }
  }

  .content-bottom {
    background-color: $gray1;
    .nocontent {
      background-color: $gray0;
    }
  }
}

.fa-clock-o, .fa-map-marker {
  color: $title-blue;
}

.footer_item {
  p {
    a {
      i.fa-map-marker {
        color: $footer-text-color;
        padding-right: 4px;
      }
    }
  }

}

.extraspace {
  padding-bottom: 28px;
}

.item_content_medium {
  .grid_container {
    background-color: transparent;
  }
}

.location {
  a{
    color: $title-blue;
    &:hover {
      color: darken($title-blue, 20%);
    }
  }
}

.content-top {
  p {
    a{
      color: $link-red;
      &:hover {
        color: darken($link-red, 20%);
      }
    }
  }
}

.table-buttons {
  .imgoing, .invite_friends_button, a {
    color: $title-blue;
  }
}

div#x-confirm-buttons-wrapper {
  .sidebar-topnav {
    @media only screen and (max-width: 959px) {
      div.table-buttons>* {
        text-align: center;
      }
    }
  }
}

//Sidebar - Change Plans link
.widget-plans {
  h4 {
    a {
      color: $title-blue;
    }
  }
}

.extra_details dd {
  a {
    color: $link-red;
    &:hover {
      color: darken($link-red, 20%);
    }
  }
}

.extra_details dd {
  word-break: break-word;
  color: $link-red;
}

//Bottom Form Section - Comment, Review and Photo
.content-bottom {
  .recent_activity_list {
    .link_action {
      .btn {
          border-radius: 0;
          background-color: $link-red;
          border: none;
          padding: 10px 14px 10px 14px;
          &:hover {
            background-color: darken($link-red, 20%);
          }
        }
    }

    input[type=submit].btn-color {
      border-radius: 0;
      background-color: $link-red;
      border: none;
      padding-left: 15px;
      padding-right: 15px;
      &:hover {
        background-color: darken($link-red, 20%);
      }
    }
  }
}

//Modal Form - Invite Friends
.localist_modal_form {
  .form_action_buttons {
     input[type=submit].btn-color {
            border-radius: 0;
            background-color: $link-red;
            border: none;
            &:hover {
              background-color: darken($link-red, 20%);
            }
          }
   }
}

//Single Event - right sidebar
.widget {
  .widget-content {
    //Event Owner - button to edit event
    a.btn.btn-color {
      border-radius: 0;
      background-color: $link-red;
      border: none;
      &:hover {
        background-color: darken($link-red, 20%);
      }
    }

    //People interested - table
    table.icon_item {
      margin-bottom: 0;
      tbody {
        border: none;
        tr td {
          border: none;
        }
      }
    }
  }
}

//Ticket URL if there is any.
.ticket_action {
  a.btn.btn-color-alt {
    background-color: $link-red;
    border: none;
    border-radius: 0;
    color: white;
    &:hover {
      background-color: darken($link-red, 20%);
    }
  }
}

.summary {
  a{
    color: $gray7;
    &:hover {
      color: darken($gray7, 20%);
    }
  }
}

//List page - social media links
.subscribe_links {
  a {
    color: $link-red;
    &:hover {
      color: darken($link-red, 20%);
    }
  }
}

//List page - Event Date and Location div
.item_content_medium {
  .actionbar {
    .left, .right {
      width: 100%;
    }
  }
}


//List page - Event Date
.dateright {
  color: $gray5;
  font-weight: 600;
}

//List page - I'm interested/Not interested button
.actionbar {
  .action_button {
    a.imgoing, a.cancelplans, a.buytickets {
      border-radius: 0;
      background: $link-red;
      border: none;
      &:hover {
        background-color: darken($link-red, 20%);
      }
    }
  }
}

//List page -
.event_group {
  .item>.box_left img {
    border: none;
    border-radius: 0;
  }

  .pagination_container {
    .page_link {
      a {
        color: $link-red;
      }
    }
  }
}

//Arrows - next and previous
.pagination_container {
  a.pagearrow {
    color: $title-blue;
  }
}

//List page
.widget {
  .widget-content {
    p {
      a {
        color: $link-red;
        &:hover {
          color: darken($link-red, 20%);
        }
      }
    }
  }
}

//List page - Submit an Event button
.sidebar-topnav {
  .btn.btn-color-alt {
    background-color: white;
    border: 1px solid $light-gray;
    border-radius: 0;
    color: $link-red;
    &:hover {
      background-color: $link-red;
      color: $white;
      border-color: $link-red;
    }
  }
}

//List page - Submit an Event button
.sidebar {
  .submit_event_button_component {
    margin-top: 27px;

    .widget {
      border: none;
      .widget-content {
        padding: 0;
      }
    }

    .btn.btn-large {
      padding: 11px 21px 16px 21px;
    }

    .widget {
      .widget-content {
        .btn.btn-color-alt {
          background-color: white;
          border: 1px solid $light-gray;
          border-radius: 0;
          color: $link-red;
          &:hover {
            background-color: $link-red;
            color: $white;
            border-color: $link-red;
          }
        }
      }
    }
  }
}

//List page - Social Media links - sidebar
.sidebar {
    .widget {
        .widget-content {
          ul {
            li {
              a, .fa-clock-o {
                color: $link-red;
                &:hover {
                  color: darken($link-red, 20%);
                }
              }
            }
          }
        }
    }
}

//Top Menu Section - Sign Up
#menu .action_menu li a {
  color: $link-red;
  font-weight: 700;
  font-size: 11px;
  i {
    color: $title-blue;
  }
}

#menu {
  width: 100%;
  float: right;
  margin-right: 20px;
  clear: right;
  text-align: right;
  text-transform: uppercase;
  padding-top: 15px;

  @include breakpoint(large) {
    position: relative;
    top: -136px;
    height: 0;
  }

  a.nav-button {
    padding: 25px 20px;
    @include breakpoint(medium) {
      padding: 7px 20px;
    }

    span.icon-bar {
      background-color: #2d6cc0;

      @include breakpoint(medium) {
        background-color: white;
      }
    }

  }

  .action_menu.expanded {
    position: relative;
    top: -52px;
  }

  .action_menu {
    li {
      display: inline-block;
    }

    .login {

      top: 0;
      position: relative;

      @include breakpoint(large) {
        top: -10px;
      }

      .fa-sign-in:before, .fa-user-plus:before {
        content: '';
      }

      a[href*="//"]:after {
        display: inline-block;
        font-family: "FontAwesome";
        font-size: inherit;
        text-rendering: auto;
        line-height: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f101"; //"\F13E";
        padding-left: 4px;
      }
    }

    li.profile_icon {
      margin-bottom: 1rem;
    }

  }
}

#top-submit {
  display: inline-block;
  background-color: transparent;
  border: none;
  padding: 0px;
  margin-left: 270px;
 // margin-top: 6px;
  width: 10%;
  float: right;
  margin-top: -28px;
}

@media screen and #{breakpoint(large)} {
  #top-submit {
    display: inline-block;
    background-color: transparent;
    border: none;
    padding: 0px;
    margin-left: 270px;
    width: 10%;
    float: right;
    margin-top: -28px;
  }
}


/////Sign-up form
.form_action_buttons {
  input[type=submit].btn-color {
    border-radius: 0;
    background-color: $link-red;
    border: none;
    &:hover {
      background-color: darken($link-red, 20%);
    }
  }
}

.form_box {
  .action_button {
    .btn-color {
      border-radius: 0;
      background-color: $link-red;
      border: none;
      &:hover {
        background-color: darken($link-red, 20%);
      }
    }
  }
}

//Create Event
.editable {
  p {
    a {
      color: $link-red;
      &:hover {
        color: darken($link-red, 20%);
      }
    }
  }
}

.homepage_nav_container {
  margin-top: 14px;
}

.item.sponsored {
  background-color: $gray3;
}

.actionbar {
  .action_button {
    a {
      padding: 10px 14px 10px 14px;
    }
  }
}



