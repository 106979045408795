// File contains CSS for the Site Logo, Site Title, and the Slogan Text. For Desktop & Mobile Views.
// Masthead scss file from Drupal - modified for silkwrapper //
// -------------------------------------------
.masthead-container {
  margin: 11px 0 10px 0;
  .masthead-logo {
    position: relative;
    display: inline-block;
    overflow: hidden;
    text-align: right !important;
    width: 78px;
    height: 57px;
    // -- Absolute path for image file -- //
    background: url('https://assets.ucr.edu/localist/images/UCR-monogram-over-white-bg.svg') no-repeat;


    @media screen and #{breakpoint(large)} {
      position: relative;
      display: inline-block;
      overflow: hidden;
      text-align: left !important;
      width: 203px;
      height: 57px;
      // -- Absolute path for image file -- //
      background: url('https://assets.ucr.edu/localist/images/ucr-horizontal-logo-std.svg') no-repeat;
    }
  }
  .masthead-title {
    display: inline-block;
    vertical-align: top;
    position: relative;
    //font-family: "Trade Gothic Next W01", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-family: "Fira Sans Medium","Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.08;
    padding-left: 7px;
    margin-bottom: 0;
    left: -20px;

    @media screen and #{breakpoint(large)} {
      font-size: 1.2rem;
      padding-left: 12px;
      margin-bottom: 7px;
      left: -20px;
    }

    a {
      color: #003DA5; //$ucr-blue;

      &:hover {
        color: #003DA5; //$ucr-blue;
      }
    }
  }

  .slogan {
    position: relative;
    max-width: 21.25rem;
    text-align: right;
    color: $slogan-color;
    font-family: "Fira Sans Regular", sans-serif;
    //font-family: "Avenir Next W01", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.2rem;
    line-height: 1.08;
    font-weight: 400;
    letter-spacing: 1.65px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }
}
