// Styles required for Localist user profile pages //
// -------------------------------------------
//Localist user profile - dashboard
.widget {
  .widget-content {
      .item {
        color: $link-red;
        h3 span a {
          color: $link-red;
          &:hover {
            color: darken($link-red, 20%);
          }
        }
      }
  }
}

.item {
  h3 {
    a {
      i {
        color: $link-red;
        &:hover {
          color: darken($link-red, 20%);
        }
      }
    }
  }

  .description {
    font-family: $trade-gothic;
  }
}

//Localist user profile - My Calendar
.pagination_container {
  .page_link {
    a {
      color: $link-red;
    }
  }
}

//Localist user profile - Friends and Places
.tabs {
  a.selected {
    color: $link-red;
  }

  a {
    color: $gray5;
  }
}

//Localist user profile -
.widget-content {
  span.label {
    background-color: white;
    color: black;
  }
}

//Localist user profile - Review
.message_group {
  .item {
    .action_button {
        input[type=submit].btn-color {
          border-radius: 0;
          background-color: $link-red;
          border: none;
          &:hover {
            background-color: darken($link-red, 20%);
          }
        }
    }
  }
}

//Localist user profile - Social Media buttons
.widget {
  .widget-content {
    .login_methods {
      .action_button {
        p {
          a {
            color: white;
          }
        }
      }
    }
  }
}

//Localist user profile - Public Profile
.item-mini {
  .heading {
    a {
      color: black;
    }
  }
}

//Localist user profile -
.nocontent {
  p {
      .btn.btn-color-alt {
        color: white;
        border-radius: 0;
        background-color: $link-red;
        border: none;
        &:hover {
          background-color: darken($link-red, 20%);
        }
      }
  }
}

//Localist user profile -
.action_button a {
  border-radius: 0;
}

//Localist user profile - Reviews
.item {
  .item_content_tiny {
    h3 {
      a{
        color: $link-red;
      }
    }
  }
}

//Localist Channel Admin profile - Edit buttons Listing page
.channel-header {
  .action_button {
    a {
      border-radius: 0;
      border: none;
    }

    a.btn-color {
      background-color: $link-red;
      &:hover {
        background-color: darken($link-red, 20%);
      }
    }
  }
}

.drop-target-text {
    .btn.btn-color {
      border-radius: 0;
      border: none;
      background-color: $link-red;
      &:hover {
        background-color: darken($link-red, 20%);
      }
    }
}

.content-inner-pages {
  .form_action_buttons {
    .btn.btn-color, input[type=button].btn-color {
      border-radius: 0;
      border: none;
      background-color: $link-red;
      &:hover {
        background-color: darken($link-red, 20%);
      }
    }
  }

}

//Localist user profile - Photos
select {
  width: 15%;
}

