// File contains CSS for the entire footer area.
// -------------------------------------------
$footer-column-border: $white;
$footer-cell-img-border: #e0e0e0;
$footer-search-border-bottom: #FFB81C;
footer {
  min-height: 100px;
  background-color: #003DA5;
  z-index: 0;
  position: relative;
  backface-visibility: hidden;

  color: $footer-text-color;
  font-family: "Fira Sans Medium", sans-serif;
  //font-family: "Avenir Next W01", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 0.6875rem;
  line-height: 1.364;
  letter-spacing: 0.06875rem;
  @media screen and #{breakpoint(large)} {
    min-height: 300px;

    &:after {
      content: "";
      background: url('https://assets.ucr.edu/localist/images/bg-ucr-footer.jpg') 50%;
      background-size: cover;
      opacity: 0.15;
      z-index: -1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }

  .footer-google-search {
    padding:18px 7px 14px 7px;
    border-bottom: 1px solid $footer-search-border-bottom;
    margin-bottom: 7px;

    .google-search {
      text-align: center;
      input {
        display: inline-block;
        margin: 0;
        border-radius: 0;
        border: 1px solid lightgrey;
        background-color: transparent;
        min-width: 195px;
        max-width: 600px;
        background: url("https://assets.ucr.edu/localist/images/google-search-footer.png") no-repeat;
        background-position: 5% 50%;
        color: $white;

        &:focus {
          outline: none;
          border: 1px solid grey;
          box-shadow: 0 0 5px grey;
          background: none;
        }

        &::placeholder {
          opacity: 1;
          position: relative;
          border: none;
          box-shadow: none;
          margin-bottom: 0;
        }

        @-moz-document url-prefix() {
          &::placeholder {
            left: -14px;
          }
        }

        &:focus::placeholder {
          background-image: none;
        }
      }
    }
  }

  .footer-columns {
    padding-top: 0.625rem;
    min-height: 100px;
    .cell {
      border-right: none;
      padding-bottom: 0.625rem;
      margin-bottom: 0.625rem;

      @media screen and #{breakpoint(large)} {
        border-right: 1px solid $footer-column-border;
        padding-bottom: 0.9375rem;
        margin-bottom: 0.9375rem;

        &:last-child {
          border-right: none;
        }
      }

    }
  }

  .footer-item {
    border-bottom: 1px dotted $footer-cell-border;
    margin: 5px 0 5px 0;

    @media screen and #{breakpoint(large)} {
      margin: 7px 0 14px 0;
    }
    h3 {
      color: $footer-text-color;
      font-family: "Fira Sans Bold","Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-size: rem-calc(10);
      font-weight: 700;
      line-height: 1.444;
      letter-spacing: rem-calc(1);
      text-transform: uppercase;
      margin-bottom: 1.5em;
      padding-bottom: rem-calc(4);
      border-bottom: 1px solid #FFB81C;
    }

    h4 {
      color: #d8e4f3;
      margin-bottom: 3px;
    }

    h4.separator {
      border-top: 1px dotted;
      padding-top: 34px;
      padding-bottom: 3px;
    }

    p {
      color: $footer-text-color;
      font-family: "Fira Sans Medium","Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      //font-family: $avenir;
      font-size: rem-calc(11);
      line-height: 1.364;
      letter-spacing: rem-calc(1.1);
    }

    a {
      &::after {
        content: "";
        padding: inherit;
      }
    }

    a:not(.button) {
      color: #fff;
      //border-bottom: 1px dotted; //Localist

      &:hover, &:focus {
        color: $page-footer-links-hover-color;
      }

      img {
        opacity: .7;
        border-radius: 1px;
        left: rem-calc(-21px);
        position:relative;

        border: 1px solid $footer-cell-img-border;

        &:hover, &:focus {
          opacity: .9;
        }
      }

      &.link-alt {
        border-bottom: none;
        font-size: rem-calc(10);
        line-height: 1.3;
        text-transform: uppercase;
      }
    }

    .fa-map-marker {
      color: $page-footer-links-color;
      margin-right: 2px;
      font-size: 16px;

    }

    ul {
      list-style-type: none;
      li {
        text-indent: -1em;
        font-size: 0.75rem;
        line-height: 1.2rem;
        a {
          &::after {
            content: "";
            display: none;
          }
        }
      }
    }

    .footer-logo {
      position: relative;
      display: inline-block;
      overflow: hidden;
      text-align: left !important;
      width: 12.5rem;
      height: 2.9375rem;
      background: url('https://assets.ucr.edu/localist/images/UC_Riverside_Horiz_BluBG.svg') no-repeat;
    }

    .button-group {
      .button {
        font-size: 1.495rem;
        padding: 4px 0 2px 2px;
        margin-right: 4px;
        background-color: #FFB81C;
        color: #003DA5;
        border-radius: 4px;
        margin-bottom: 4px;
        /*&::after {
          content: "";
        }*/


        &.mdi-facebook {
          &:hover, &:focus {
            background-color: $footer-button-facebook;
          }
        }

        &.mdi-twitter {
          &:hover, &:focus {
            background-color: $footer-button-twitter;
          }
        }

        &.mdi-youtube-play {
          &:hover, &:focus {
            background-color: $footer-button-youtube;
          }
        }

        &.mdi-instagram {
          &:hover, &:focus {
            background-color: $footer-button-instagram;
          }
        }

        &.mdi-linkedin {
          &:hover, &:focus {
            background-color: $footer-button-linkedin;
          }
        }

        &.mdi-camera-iris {
          &:hover, &:focus {
            background-color: $footer-button-flickr;
          }
        }

        &.mdi-rss {
          &:hover, &:focus {
            background-color: $footer-button-rss;
          }
        }
      }
      ///Localist////
      a.mdi-twitter, a.mdi-facebook, a.mdi-youtube-play, a.mdi-instagram, a.mdi-linkedin  {
        display: inline-block;
        font-family: "FontAwesome";
        font-size: 30px;
        text-rendering: auto;
        line-height: 2rem;
        -webkit-font-smoothing: antialiased;
        padding: 0px 2px 0px 2px;

      }

      a.mdi-twitter {
        &:after {
          content: "\f081";
        }
      }

      a.mdi-facebook {
        &:after {
          content: "\f082";
        }
      }

      a.mdi-youtube-play {
        &:after {
          content: "\f16a";
        }
      }

      a.mdi-linkedin {
        &:after {
          content: "\f08c";
        }
      }

      a.mdi-instagram {
        &:after {
          content: "\f16d";
        }
      }
    }

    input {
      background-color: $footer-input-background;
      color: $white;

      &:focus {
        outline: none;
        border: 1px solid grey;
        box-shadow: 0 0 5px grey;
        background: $footer-input-background;
      }
    }
  }

  .ucr-footer-info {
    @media screen and #{breakpoint(large)} {
      border-bottom: none;
    }

    address {
      font-style: normal;
    }

    .footer-links {
      margin: 6px 0 14px 0;

      &::before {
        display: block;
        content: "";
        margin-bottom: .5rem;
        border: 1px solid $footer-ucr-info-line-color;
        width: 2em;
      }

      li {
        border-bottom: 1px solid $footer-cell-border;
        margin: 0;
        font-family: "Fira Sans Medium", sans-serif;
        //font-family: "Avenir Next W01", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-size: 0.75rem;
        line-height: 1.167;
        letter-spacing: .083em;
        list-style-type: none;
        text-indent: initial;

        a {
          //color: $footer-text-color;
          border: none;
          display: block;
          padding: 0.625rem;

          &:hover, &:focus {
            //background-color: rgba(220, 220, 220, 0.15);
          }

          &::after {
            content: "";
          }
        }
      }
    }

    .footer-links-other {
      text-decoration: underline;
      margin-bottom: 15px;
      display: block;
      margin-left: 4px;
      //border-bottom: 1px dotted;
      li {
        font-family: "Avenir Next W01", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-size: 0.7rem;
        line-height: 1.167;
        //letter-spacing: .083em;
        list-style-type: none;

        a {
          color: $footer-text-color;
          border: none;
          display: block;
          padding: 0.4rem 0.6875rem;

          &:hover, &:focus {
            background-color: rgba(220, 220, 220, 0.15);
          }

          &::after {
            content: "";
          }
        }
      }
    }
  }

  .footer-dept-info {
    address {
      font-style: normal;
      padding-bottom: 15px;
    }

    a {
      &.link-img {
        display: block;
        border-bottom: none;
        &-location {
          text-transform: uppercase;
        }

        &::before {
          display: inline-block;
          font: normal normal normal 24px/1 "Material Design Icons";
          font-size: 1.5rem;
          vertical-align: middle;
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          padding-left: 4px;
          content: "\f34e";
        }
      }

      img {
        margin-left: 21px;
      }
    }
  }

  .site-legal-footer {
    background-color: $footer-legal-background;
    color: $footer-legal-color;
    font-family: "Fira Sans Bold","Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-size: 12px;
    letter-spacing: .025rem;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 18px 0;
      li {
        display: inline-block;
        &::before {
          content: "";
          padding-right: 5px;
        }
        &:first-child::before {
          padding-right: 0;
        }
        &::after {
          content: "";
          border-right: 1px solid $white;
          padding-left: 7px;
        }
        &:last-child::after {
          border: none;
          padding-left: 0;
        }
        a {
          color: $footer-legal-color;
          &::after {
            content: "";
            padding: inherit;
          }
        }
      }
    }
  }

}