// Styles required for Localist Channels - if there are channels created by admin //
// -------------------------------------------
//Featured Events slider
.tabs_component {
  .featured_carousel .featured_nav a {
    color: $black;
    background-color: $gray2;
  }

  .featured_carousel .featured_nav a.current {
    background-color: $link-red;
    color: $white;
  }

  .featured_section_component {
    background-color: $gray1;
  }
}

.featured_carousel .featured_item_wrapper .item .summary {
  a {
    color: #666666;
  }
}

.featured_item_wrapper {
  .item {
    background-color: $gray1;
  }
}

//Homepage Tabs
.tabs {
  border: none;
}

.tabs>* {
  margin-left: 4px;
}

.tabs .selected_tab_colorfive {
  background-color: $link-red;
  border: 1px solid $link-red;
  border-radius: 0;
  padding: 7.5px 9px 8.5px 9px;
  color: white;
}

a.dark_colorfive {
  background-color: white;
  border: 1px solid $gray4;
  border-radius: 0;
  padding: 7.5px 9px 8.5px 9px;
  color: $gray6;
  border-bottom: none;
}

a.dark_colorfive.selected_tab_colorfive {
  color: white;
}

//Sidebar - Filter links - Event Types, Audience
.sidebar {
  .filter_list_component, .filter_list {
    .widget {
      .widget-content {
        ul {
          li {
            a {
              font-size: 0.9rem;
              font-weight: 700;
              color: $gray6;
              &:hover {
                color: darken($gray6, 20%);
              }
            }
          }
        }
      }
    }
  }
}

.table-buttons.horizontal-nav>* .icon {
  padding-right: 10px;
}

.action_button.pull-right {
  clear: right;
}

.channel-header {
  margin-right: 20px;
}

div.featured_carousel {
  div.featured_item_wrapper {
    .item {
      img {
        @include breakpoint(small) {
          border-radius: 0px;
          margin: 0 25px 0 0;
        }
      }
    }
  }
}

/*
@media screen and #{breakpoint(large)} {
  .featured_carousel {
    height: 202px;
  }
}*/

