// File contains CSS for the Parent Org Title, Audience Menu, and Site-Top Search Bar.
// The Site-Top Search Bar & Audience Menu CSS in here is for primarily Desktop Views. For Mobile views, look in the 'primary_navigation.scss' file.
// Masthead scss file from Drupal - modified for silkwrapper //
// -------------------------------------------

$audience-mobile-view-background: #003DA5;
$audience-border: #b0bec5;
$audience-org-color: #78909c;
.audience-container {
  max-height: 45px;
  background-color: $audience-mobile-view-background;
  border-bottom: 1px solid $audience-border;
  position: relative;
  line-height: normal;

  .mobile-menu-expander {
    font-size: 2rem;
    height: 100%;
    padding: 0 7px;
    margin: 0;
  }

  @media screen and #{breakpoint(large)} {
    background-color: transparent;
    border-bottom: 1px solid $audience-border;
    padding: inherit;
  };

  .parent-org-title {
    text-transform: uppercase;
    font-family: "Fira Sans Medium", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.01px;
    line-height: 1;
    display: inline-block;
    max-width: 100%;
    padding: 8px 0;
    margin: 0;
    a {
      color: $white;
      &::after {
        content: "";
        padding-left: 0;
      }
    }

    @media screen and #{breakpoint(large)} {
      font-size: 1rem;
      font-weight: inherit;
      padding: 0;
      a {
        color: $audience-org-color;
        letter-spacing: 0;
        &:hover {
          border-bottom: 1px dotted;
        }
      }
    }
  }

  .google-search {
    display: none;
    border-left: 1px solid $audience-border;
    height: 100%;
    overflow: hidden;

    @include breakpoint(large) {
      display: block;
    }

    input {
      margin:0;
      padding:0 0 0 16px;
      border-radius: 0;
      border: none;
      box-shadow: none;
      background-color: transparent;
      min-width: 195px;
      max-width: 100%;
      background: url("https://assets.ucr.edu/localist/images/google-search-top.png") no-repeat;
      background-position: 50% 50%;

      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
        background: none;
      }

      &::placeholder {
        opacity: 1;
        position: relative;
        border: none;
        box-shadow: none;
        margin-bottom: 0;
      }

      @-moz-document url-prefix() {
        &::placeholder {
          left: -14px;
        }
      }

      &:focus::placeholder {
        background-image: none;
      }
    }
  }

  .audience-links {
    .audience-button {
      a {
        font-weight: 600;
        font-size: 0.685rem;
        letter-spacing: 0.0375rem;
        font-family: "Avenir Next W01", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        text-transform: uppercase;
        color: $alternate;
      }
      > a {
        padding:15px 30px 13px 0 !important;
        &::after {
          right: 12px !important;
          padding-right: 0;
          content: "" !important;
          border-color: $alternate transparent transparent !important;
        }
      }

      ul {
        background-color: $audience-menu-background;
        border: 1px solid $audience-menu-border;
        border-radius: 2px;
        border-bottom: none;
        font-size: 1rem;
        box-shadow: 1px 1px 4px $audience-menu-shadow;

        li {
          border-bottom: 1px solid $audience-menu-border;
        }

        a {
          padding: 14px 7px !important;
          color: $audience-menu-link;
          &::after {
            position: relative !important;
            top: 0 !important;
            right: 0 !important;
            margin-top: 0 !important;
          }
          &:hover {
            background-color: $audience-menu-link-hover-bg;
            color: $audience-menu-hover;
          }
        }
      }
    }
  }
}
