// Define a font-size for HTML for consistent REM unuits
// -------------------------------------------
html {
  font-size: 100%;//16px on most browsers
}
body {
  font-family: "Trade Gothic Next W01", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size: 100%;
  line-height: 1.5;
  color: $black;
  background: $white;
  -webkit-font-smoothing: antialiased;
}


a[href*="//"]:after {
  display: inline-block;
  //font: normal normal normal 24px/1 "Material Design Icons";
  //font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //content: "\F3CC";
  padding-left: 4px;
}

.grid-container { max-width: 75rem; }

h1, h2, h3, h4, h5, h6 {
  font-family: $trade-gothic;
  font-weight: normal;
  font-style: normal;
  color: #666666;
  margin-top: 0;
  margin-bottom: 0.75rem;
  line-height: 1.4;
}
h1, h2 {
  margin-bottom: 2rem;
  font-weight:300;
}

table {
  thead {
    tr {
      th {
        border-right: 1px solid $white;
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
  tbody {
    tr {
      th {
        background-color: $color-picton-blue;
        color: $white;
        border-top: 1px solid $white;
        border-bottom: 1px solid $white;
        border-right: 1px solid $color-ghost;
      }
      td {
        border: 1px solid $color-ghost;
      }
      &:last-child {
        th {
          border-bottom: 0;
        }
        td {
          border-bottom: 0;
        }
      }
    }
  }
}

.ucr-custom-block {
  margin-bottom: 1.5rem !important;
}

// Module Override Styles:
.color_field_widget_box__square {
  border:1px solid #666666;
}

.separator-line-before {
  @include line-separator('before');
}

.separator-line-after {
  @include line-separator('after');
}

.separator-line-maroon-before {
  @include line-separator('before', $color-cerise-red);
}

.separator-line-maroon-after {
  @include line-separator('after', $color-cerise-red);
}

.separator-line-dark-before {
  @include line-separator('before', $color-astronaut);
}

.separator-line-dark-after {
  @include line-separator('after', $color-astronaut);
}

.btn-ucr {
  display: inline-block;
  text-decoration: none !important;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
  background-color: $color-picton-blue;
  color: $white !important;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: darken($color-picton-blue, 20%);
  }
}
.btn-ucr-alt {
  @extend .btn-ucr;
  background-color: $color-cerise-red;
  &:hover {
    background-color: darken($color-cerise-red, 20%);
  }
}
.btn-ucr-split {
  @extend .btn-ucr;
  position: relative;
  background-color: $white;
  color: $color-picton-blue !important;
  border: 2px solid darken($color-picton-blue, 20%);
  &:after {
    display: inline-block;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 1.5rem !important;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\F142" !important;
    position: absolute;
    top: -2px;
    left: 100%;
    padding: 0.1rem 0.4rem;
    border: 2px solid darken($color-picton-blue, 20%);
  }
  &:hover {
    background-color: $color-picton-blue;
    color: $white !important;
    &:after {
      background-color: $color-picton-blue;
    }
  }
}
a {
  &.btn-ucr-split {
    &:after {
      font-size: 1.5rem !important;
      content: "\F142" !important;
    }
  }
}

.list-arrow {
  list-style: none;
  li {
    &:before {
      display: inline-block;
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: inherit;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\F733";
      padding-right: .25em;
    }
    ul {
      list-style-type: none;
      li {
        &:before {
          color: lighten($color-primary-content, 15%);
        }
        ul {
          li {
            &:before {
              color: lighten($color-primary-content, 30%);
            }
          }
        }
      }
    }
  }
}

.list-arrow-alt {
  list-style: none;
  li {
    &:before {
      display: inline-block;
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: inherit;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\F142";
      padding-right: .25em;
    }
    ul {
      list-style-type: none;
      li {
        &:before {
          color: lighten($color-primary-content, 15%);
        }
        ul {
          li {
            &:before {
              color: lighten($color-primary-content, 30%);
            }
          }
        }
      }
    }
  }
}
