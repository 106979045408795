// Styles required for Localist Calendar - Mini calendar widget on right sidebar //
// -------------------------------------------
//List page - Right Sidebar containing Calendar widget
.sidebar {
  // Widget Headers
  .widget {
    .widget-header {
      color: $title-blue;
      h3 {
        color: $title-blue;
      }
    }
  }

  .minicalendar_component {
    background-color: #e6e6e6;
    .widget {
      background-color: transparent;
    }
  }

  div.widget.mini_calendar {
    background-color: #e6e6e6;
  }

  //Calendar widget - table
  .widget.mini_calendar {
    table.minicalendar {
      margin-bottom: 0;
      tbody {
        tr {
          th {
            font-family: "Trade Gothic Next W01", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
            color: $gray5;
            background-color: $light-gray;
            font-size: 0.9rem;
            font-weight: 700;
            border-right: none;
            border-top: none;
          }
          td {
            font-family: "Trade Gothic Next W01", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
            font-size: 0.9rem;
            background-color: $gray1;

          }
          td.selected {
            a {
              background-color: $link-red;
              color: $white;
            }
          }
        }
      }

      tbody tr:nth-child(even) {
        background-color: $gray1;
      }

      tbody tr td {
        border: none;
        padding-right: 0;
        padding-left: 0;
      }


      tbody {
        border: none;
      }

      tbody[data-minicalendar="calendar"] td a {
        color: $gray8;
      }
    }

    //Heading for the calendar (month name)
    .page_heading {
      border-bottom: none;
    }

    // Prev and next month icon
    .pagination_container {
      a#localist-minical-prev-month, a#localist-minical-next-month {
        color: $title-blue;
      }
      .minical {
        margin-bottom: 0px;
        background-color: $light-gray;
      }

    }
  }

  // View today button
  .mini_calendar {
    .subcal_actions {
      .btn {
        background-color: $link-red;
        border: none;
        border-radius: 0;
        &:hover {
          background-color: darken($link-red, 20%);
        }
      }
    }
  }
}
