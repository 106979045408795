#login-lightbox {
  .signup_statement {
    margin-top: 1em;
    border-top: 1px solid #efefef;
    padding-top: 1em;
    border-bottom: none;
  }
  a.profile_cas_button {
    background-color: $link-red;
    border-color: $link-red;
    &:hover {
      background-color: #d80e0e;
      border-color: #d80e0e;
    }
  }
  .login_methods {
    margin-top: 0;
    padding-top: 0;
    border-bottom: 1px solid #f0f2f3;
    padding-bottom: 1em;
    border-top: none;
  }
  input#login_user {
    margin:0!important;
  }
  .top2M {
    margin-top:2em;
  }
}
