// File contains CSS for the Main Navigation Bar. For the bar, this deals with both Desktop & Mobile views.
// The Search Bar CSS & Audience Menu Dropdown in here is for primarily Mobile views. For the Desktop views, look in the 'masthead-audience.scss' file.
// Navigation scss file from Drupal - modified for silkwrapper //
// -------------------------------------------
.main-nav-bar {
  font-family: "Avenir Next W01", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 0.6875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  position: relative;

  background-color: $primary-menu-mobile-background;
  box-shadow: 0 2px 3px $drop-shadow-color;
  .accordion-menu {
    border: 1px 0 solid $audience-border;
    margin: 0 -10px;
    li {
      border-top: 1px solid $audience-border;
      position: relative;
      .is-accordion-submenu-item {
        &:first-child {
          border-top: 1px solid $audience-border;
        }
      }
      a {
        color: $primary;
        &:hover, &:active {
          background-color: $primary-menu-mobile-hover-background;
          color: $white;
        }
      }
    }

    .is-accordion-submenu-parent {
      &:not(.has-submenu-toggle) {
        > a {
          &::after {
            @include css-triangle($accordionmenu-arrow-size, $primary, down);
          }
          &:hover {
            &::after {
              @include css-triangle($accordionmenu-arrow-size, $white, down);
            }
          }
          &:active {
            &::after {
              @include css-triangle($accordionmenu-arrow-size, $white, down);
            }
          }
        }
      }
    }
    .is-accordion-submenu-parent[aria-expanded='true'] {
      > a {
        &:hover, &:active {
          &::after {
            border-color: $white transparent;
          }
        }
      }
    }
  }
  .google-search {
    text-align: center;

    #audience-search {
      width: 60%;
      display: inline-block;
      margin: 7px;
      padding: 0;
      vertical-align: middle;
      background: url("http://srtest2.dev-ucr.acsitefactory.com/sites/g/files/rcwecm5451dev/files/google-search-top.png") no-repeat;
      background-color: $white;
      background-position: 50% 50%;

      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
        background: $white;
      }

      &::placeholder {
        opacity: 1;
        position: relative;
        border: none;
        box-shadow: none;
        margin-bottom: 0;
      }

      @-moz-document url-prefix() {
        &::placeholder {
          left: -14px;
        }
      }

      &:focus::placeholder {
        background-image: none;
      }
    }
    button {
      margin: 0;
    }

  }

  #audience-links-mobile-view {
    a {
      background-color: $white;
      color: $audience-menu-mobile-color;
      &:hover, &:active {
        background-color: $audience-menu-mobile-hover-background;
      }
    }
    .audience-button {
      > a {
        color: $audience-menu-mobile-button;
      }
    }
    .is-accordion-submenu-parent {
      &:not(.has-submenu-toggle) {
        > a {
          &::after {
            @include css-triangle($accordionmenu-arrow-size, $audience-menu-mobile-button, down);
          }
          &:hover {
            &::after {
              @include css-triangle($accordionmenu-arrow-size, $audience-menu-mobile-button, down);
            }
          }
          &:active {
            &::after {
              @include css-triangle($accordionmenu-arrow-size, $audience-menu-mobile-button, down);
            }
          }
        }
      }
    }
    .is-accordion-submenu-parent[aria-expanded='true'] {
      > a {
        &:hover, &:active {
          &::after {
            border-color: $audience-menu-mobile-button transparent;
          }
        }
      }
    }
  }

  @media screen and #{breakpoint(large)} {
    background-color: $primary;

    #main-menu {
      background-color: $primary;
    }

    .dropdown {
      li {
        box-shadow: inset 0 0.9375rem 0 0 $primary, inset 0px -0.9375rem 0 0 $primary, inset 1px 0 0 0 $white;
        margin-left: 1px;
        padding-left: 1px;
        &:first-child {
          box-shadow: none;
          margin-left: 0;
          padding-left: 0;
        }
      }
      a {
        padding: 1.05rem 1rem;
        color: $white;
        &::after {
          right: 11px !important;
          border-color: $white transparent transparent !important;
        }
        &:hover {
          background-color: $primary-menu-hover;
          color: $primary-menu-hover-color;
        }
      }
      .is-dropdown-submenu-parent {
        a {
          padding-right: 2rem !important;
        }
      }
      .is-active {
        > a {
          background-color: $primary-menu-hover;
          color: $primary-menu-hover-color;
          &::after {
            right: 11px !important;
            border-color: $primary-menu-hover-color transparent transparent !important;
          }
        }
      }
      .is-dropdown-submenu {
        background-color: $primary-menu-sub-background;
        border: 1px solid $primary-menu-sub-border;
        box-shadow: 2px 2px 4px $drop-shadow-color;
        li {
          border-bottom: 1px solid $primary-menu-sub-border;
          box-shadow: none;
          margin-left: 0;
          padding-left: 0;
          &:last-child {
            border-bottom: none;
          }
        }
        a {
          padding: 0.9rem 1rem;
          &:hover {
            background-color: $primary-menu-sub-hover;
          }
          &::after {
            border-color: transparent transparent transparent $primary-menu-sub-color !important;
          }
        }
        .is-active {
          > a {
            background-color: $primary-menu-sub-hover;
            &::after {
              border-color: transparent transparent transparent $pirmary-menu-sub-hover-color !important;
            }
          }
        }
      }
    }
  }
}
