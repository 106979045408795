// File contains CSS for the primary content column within the main content area.
// -------------------------------------------
.primary-content-area {
  .general-menu {
    background-color: #ecebeb;
    border-top: 3px solid $color-picton-blue;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    h2 {
      display: block;
      margin: 0;
      padding: 0;
      padding-bottom: 1rem;
      color: lighten($color-primary-content, 15%);
      line-height: normal;
      font-size: 1.75rem;
      text-align: center;
    }
    ul {
      &.menu {
        &.vertical {
          li {
            a {
              border-top: 1px solid lighten($color-primary-content, 22%);
              text-decoration: none;
              color: $color-primary-content;
              padding: 1rem;
              &:hover {
                background-color: lighten($color-hoki, 35%);
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}